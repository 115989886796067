<template>
    <div class="body_div">
        <div class="content_div">
            <el-card>
                <div style="font-size: 30px;height: 80px;line-height: 60px;font-weight: bold;">
                    智能行情分析系统
                </div>
                <el-tabs v-model="activeName">
                    <el-tab-pane label="账号登录" name="first"></el-tab-pane>
                </el-tabs>
                <br>
                <el-form ref="form" :model="data" :rules="rules" @submit.native.prevent>
                    <el-form-item icon="el-icon-search" prop="userName">
                        <el-input prefix-icon="el-icon-place" v-model="data.userName" placeholder="请输入用户名" @keyup.enter="handleLogin('form')"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input prefix-icon="el-icon-lock" v-model="data.password" placeholder="请输入密码" show-password
                                  @keyup.enter="handleLogin('form')"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button style="width: 100%" type="success" @click="btnLogin('form')" native-type="submit('form')">登&emsp;录</el-button>
                    </el-form-item>
                </el-form>

                <el-link :underline="false">还没有账号？</el-link>
                <el-link type="success" @click="btnRegister">创建新账号</el-link>
                <br>
                <br>

            </el-card>
        </div>
    </div>
</template>
<script>
import {reqGetUserInfo, reqUsersLogin} from "@/api/base/Users";
import {setToken} from "@/utils/SessionStorage";

export default {
    name: "Login",
    created() {
    },
    mounted() {
        // this.$nextTick(() => {
        //     // 确保 DOM 元素已经渲染
        //     document.addEventListener('keyup', this.handleLogin);
        // });
    },
    // beforeDestroy() {
    //     // 移除事件监听器
    //     document.removeEventListener('keyup', this.handleLogin);
    // },
    methods: {
        btnRegister() {
            this.$router.push("/register");
        },
        goIndex() {
            this.$router.push("/home/index");
        },
        //用户登录
        async btnLogin(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let {data} = await reqUsersLogin(this.data);
                    setToken(data);
                    let res = await reqGetUserInfo({});
                    sessionStorage.setItem("loginName", res.data.nickName);
                    sessionStorage.setItem("loginInfo", JSON.stringify(res.data));
                    this.$message.success("登录成功");
                    this.goIndex();
                }
            });
        },
        async handleLogin() {
            //await this.btnLogin();
        },
    },
    data() {
        return {
            activeName: "first",
            total: 0,
            dataList: [],
            query: {},
            data: {},
            rules: {
                userName: [
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                ]
            }
        }
    }
}
</script>
<style scoped lang="scss">
.content_div {
    justify-content: center;
    width: 25%;
    //width: 480px;
    position: absolute;
    top: 30%;
    left: 35%;
}

.top_button {
    cursor: pointer;
}

.body_div {
    background-image: url("@/assets/bg.jpg");
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-color: #92a8a9;
}

.top_card {
    background-color: #77b9be;
    color: white;
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
}
</style>
